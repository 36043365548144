@use '../../scss/' as *;

.post-related-card {
    width: 100%;
    background-color: $white;
    border-radius: 4px;
    padding: 20px 40px 0;
    box-shadow: $box-shadow;

    &__title {
        font-size: $font-size-xl;
        font-weight: 500;
        margin-bottom: 20px;

        &:hover {
            text-decoration: underline $main-color;
        }

        a {
            color: $main-color;
        }
    }

    &__description {
        font-size: $font-size-m;
    }

    &__author {
        padding: 8px 0;
    }

    &__reaction {
        padding: 0 0 12px;
        span {
            margin-right: 12px;
            cursor: pointer;

            &:hover {
                color: $main-color;
                text-decoration: underline $main-color;
            }
        }
    }
}
