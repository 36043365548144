@use '../../scss/' as *;

.widget-list {
    width: 100%;
    padding: 20px;
    margin-top: 12px;
    border-radius: 4px;
    background-color: $white;
    box-shadow: $box-shadow;
}

// css seperate .widget-list into 3 column
.widget-list-vertical {
    width: 33.3333333333%;
    padding: 0 10px;
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: top;

    // @include mobile {
    //     width: 100%;
    // }
}
