$body-bg: #f4f4f5;
$main-color: #005da6;
$text-color: #333;
$border-color: #ccc;
$breadcum-bg: #2b2b2c;

$white: #fff;
$black: #000;

$box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
// $box-shadow: 2px 2px 8px rgb(0 0 0 / 10%);

$header-height: 4rem;
$header-height-mobile: 6rem;
$header-shrink-height: 5rem;
$border-radius: 30px;

$mobile-width: 600px;
$tablet-width: 1024px;

$font-family: 'Montserrat', sans-serif;

//font-size
//36px = 2.25rem
$font-size-xxl: 2.25rem;

//20px = 1.25rem
$font-size-xl: 1.25rem;

//17px = 1.0625rem
$font-size-l: 1.0625rem;

//16px = 1rem
$font-size-m: 1rem;

//14px = 0.875rem
$font-size-s: 0.875rem;

//13px = 0.8125rem
$font-size-xs: 0.8125rem;
